import React, { useState, useRef, useLayoutEffect } from 'react'
import { useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
import { useSearchParams } from 'react-router-dom';
import '../css/VideoPg.css'
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import { format } from "date-fns";
import axios from 'axios';
import { useParams } from 'react-router'


function VideoPg() {
  const playerRef = useRef(null);
  const [Loading, setLoading] = useState(true);
  const [Error, setError] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['yeccuid']);
  const [embeddingUrl, setEmbeddingUrl] = useState('');

  const [searchParams, setSearchParams] = useSearchParams({ 
    'width': '100', 
    'height': '100', 
    'action' : '0', 
    'autoPlay' : '0', 
    'mute' : '0', 
    'companyID' : 0, 
    // 'videoID' : null,
    'cookieExpiry' : 72,
  });
  
  const width = searchParams.get('width');
  const height = searchParams.get('height');
  const action = searchParams.get('action');
  const autoPlay = searchParams.get('autoPlay');
  const companyID = searchParams.get('companyID');
  // const videoID = searchParams.get('videoID');
  const mute = searchParams.get('mute');
  const cookieExpiry = searchParams.get('cookieExpiry');

  const { videoID } = useParams()
  // console.log(videoID);
  
  useEffect(() => {
    console.log({cookies})
      const uniqueid = cookies.yeccuid;

      const Expiry = cookieExpiry;
      const expryDate = Date.now();
      const convertMinutesToSec = Expiry * 60 * 60 * 1000;
      const expry = new Date(convertMinutesToSec + expryDate);
      if(!uniqueid) {
        const createUniqueId = uuidv4();
        // console.log(createUniqueId);
        setCookie("yeccuid", createUniqueId, { 
          expires: expry, 
          path: '/',
          // domain: 'embed.yecc.tech',
          sameSite: 'None',
          secure: true,
          httpOnly: false });
      }
      // console.log(uniqueid);
    if(videoID && cookies.yeccuid) {
    
    async function getURL() {
      const referrer = document.referrer;
      // console.log('referrer', referrer);
      setEmbeddingUrl(referrer);
      if(sessionStorage.getItem('SessionID') === undefined || sessionStorage.getItem('SessionID') === null) {
        sessionStorage.setItem('SessionID',  Date.now());
      }
      const sessionIDs = sessionStorage.getItem("SessionID");
      const dataToSend = {
        "CompanyID" : companyID,
        "PortalURL" : referrer,
        "ClientUUID": cookies.yeccuid,
        "TempID" : `TM${sessionIDs}`
      }

      try {
        const response = await axios.post(`https://api.yecc.tech/embedVideos/${videoID}`, dataToSend);
        // console.log(response);
        if(response.status === 200 && response.data.data) {
          // setTimeout(() => {
            setIsReady(true);
            setVideoUrl(response.data.data.VideoLink);
          // }, 1000);
        }
      } catch (err) {
        setVideoReady(true);
        setError(true);
        // console.log(err);
      }
  
      // setIsReady(true);
      //   setVideoUrl("https://yecc.sgp1.cdn.digitaloceanspaces.com/MyMedia/eutMedia/SSP/video1/Purchase-Requisition-Excel-Upload.m3u8");
    }
      const Wait = setTimeout(() => {
        getURL();
      }, 1000);
      
      return () => {
        clearTimeout(Wait);
      }
    }
  }, [cookies])

  const actions = {
    '0' : 'pause',
    '1' : 'play'
  }

  const muted = {
    '0' : false,
    '1' : true
  }

  const autoPlays = {
    '0' : false,
    '1' : true
  }


  
  const [sentDataNumb, setSentDataNumb] = useState(0);
  const [videoState, setVideoState] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [playPauseState, setPlayPauseState] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  
  const [isReady, setIsReady] = useState(false);

  async function SendData(data) {
    const sessionIDs = sessionStorage.getItem("SessionID");
    // console.log(sessionIDs, data);
    if(sessionIDs) {
      const dataToSend = {
        "CompanyID": companyID,
        "VideoID": videoID,
        "VideoData": [...data],
        "ClientUUID": cookies.yeccuid,
        "TempID" : `TM${sessionIDs}`
      }
      // dataToSend.VideoData[`TM${sessionIDs}`] = 
      try {
        const response = await axios.post('https://api.yecc.tech/embedVideo', dataToSend);
        // console.log(response);
        setSentDataNumb(prevState => prevState + 5);
      } catch (error) {
        console.error(error);
      }
    }
  }

  function UpdateUrlAction(action, value) {
    const QString = searchParams;
    const result = {};
    QString.forEach((value, key) => {
      result[key] = value;
    });
    result[action] = value;
    setSearchParams({ ...result });
  }

  const CurrentDateTime = () => {
    // return new Date().toLocaleString();
    var currentdate = new Date();
    const datetime = format(currentdate, 'yyyy-MM-dd KK:mm:ss')
    // const dt = currentdate.getDate();
    // const dmon = currentdate.getMonth()+1 <= 9 ? "0"+(currentdate.getMonth()+1) : currentdate.getMonth()+1;
    // var datetime = dt + "-" + dmon + "-" + currentdate.getFullYear() + " " + currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();
    return datetime
  }

  function UpdateVideoState(Key, Data) {
    
    function Process (data) {
      const TempState = JSON.parse(JSON.stringify(data));
      const FilteredPause = TempState.filter(item => item.Action === 'Pause')
      if(Key === 'Resume' && FilteredPause.length > 0) {
        const latestResume  = FilteredPause[FilteredPause.length - 1];
        TempState.push({"Action" : Key, "Data" : latestResume.Data, "Date" : CurrentDateTime()});
      } else {
        TempState.push({"Action" : Key, "Data" : Data, "Date" : CurrentDateTime()});
      }
      return TempState;
    }

    setVideoState((prevState) => Process(prevState))
  }

  function VideoReady(e) {
    UpdateVideoState('Ready', '00.00');
    setVideoReady(true);
    setLoading(false);
  }

  useEffect(() => {
    // console.log({sentDataNumb, videoState});
    if(videoReady && videoState.length > 0 && videoState.length % 5 === 0) {
      const dataToSend = videoState.filter((itm, i) => i >= sentDataNumb);
      // console.log(dataToSend);
      SendData(dataToSend);
    }

    // if(videoReady && videoState.length > sentDataNumb && videoState.length % 5 !== 0 && videoState.Action === 'Pause') {

    // }
  }, [videoState, videoReady])
  
  

  function VideoStart(e) {
    setIsReady(true);
    // console.log('Start playing', e);
    UpdateVideoState('Start', '00.00');
  }

  function VideoResume(e) {
    // console.log('Resuming playback', e);
    // UpdateUrlAction("Resume")
    UpdateVideoState('Resume', '00.00');
  }

  function VideoProgress(e) {
    // console.log('Progress playing', e);
    UpdateVideoState('Progress', e);
  }

  function VideoPause(e) {
    // console.log('Pausing playback', e);
    UpdateVideoState('Pause', e.target.currentTime);
  }

  function VideoSeek(e) {
    // console.log('Seeking', e);
    UpdateVideoState('Seeking', e);
  }

  function VideoEnd(e) {
    // console.log('Ending', 'end');
    UpdateVideoState('End', '00.00');
  }

  // useEffect(() => {
  //   if(action === 'pause') {
  //     setPlayPauseState(false);
  //   } else {
  //     setPlayPauseState(true);
  //   }
  //   console.log(action)
  // }, [action])

  // useEffect(() => {
  //   console.log(autoPlay, autoPlays[autoPlay])
  //   if(autoPlays[autoPlay]) {
  //     setPlayPauseState(true);
  //   }
  // }, [autoPlay])

  // useEffect(() => {
  //   console.log('playPauseState', playPauseState);
    
  // }, [playPauseState])
  

  
  useEffect(() => {
    if(actions[action] === 'pause') {
      setPlayPauseState(false)
    } else {
      setPlayPauseState(true)
    }
  }, [action]);

  useEffect(() => {
    const wait = setTimeout(() => {
      console.log(autoPlays[autoPlay]);
      if(videoReady && autoPlays[autoPlay]) {
        setPlayPauseState(true);
        // UpdateUrlAction('mute', 0)
      }
    }, 2000);

    const video = playerRef.current?.getInternalPlayer();
    if (video && typeof video.disablePictureInPicture !== "undefined") {
      video.disablePictureInPicture = true; // Disable Picture-in-Picture
    }

    return () => {
      clearTimeout(wait);
    }
  }, [videoReady])
  
  // useEffect(() => {
  //   console.log({videoState})
  // }, [videoState, autoPlay]);

  return (
    <div className={`${Loading ? 'ShowVideoLoader wrapperContainer' : 'wrapperContainer'}`}>
      {Loading ? <>
        {
          Error ?
          <>
          <div id="error-page">
            <div className="cardError VideoError">
              <img src='https://yecc.sgp1.cdn.digitaloceanspaces.com/images/file-corrupted.svg' />
              <p>Video Not Found</p>
            </div>
          </div>
          </>
          :
          <div className='LoaderContainer'><div className="loader2"></div></div>
        }
      </>
      :
      <></>}
      {/* <button onClick={() => UpdateUrlAction('action', 1)}>Play</button>
      <button onClick={() => UpdateUrlAction('action', 0)}>Pause</button> */}
      {
        videoUrl &&
        <ReactPlayer
          ref={playerRef}
          playing={playPauseState}
          progressInterval={5000}
          onReady={VideoReady}
          onStart={VideoStart}
          onPlay={VideoResume}
          onProgress={VideoProgress}
          onPause={VideoPause}
          onSeek={VideoSeek}
          onEnded={VideoEnd}
          height={`${height}`}
          width={`${width}`}
          controls
          playsinline
          pip={false}
          muted={muted[mute]}
          autoPlay={autoPlays[autoPlay]}
          url={videoUrl} />
      }
    </div>
  )
}

export default VideoPg