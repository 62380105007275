import React from 'react'

function Home() {
  return (
    <div className='h-100 d-flex justify-content-center align-items-center flex-column'>
        <img src='https://yourerpcoach.com/img/logo/yeccFull.jpg' className='w-100 max-width-3' alt='YourERPCoach Logo' />
        <a href='https://yourerpcoach.com' target='_blank' className="link">Visit Website</a>
    </div>
  )
}

export default Home